import { FETCH_PAYMENT_REPORT } from "../actions/paymentReport";

export default function paymentReport(state = {}, action) {
  switch (action.type) {
    case FETCH_PAYMENT_REPORT:
      return {
        ...action.students,
      };
    default:
      return state;
  }
}
