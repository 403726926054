import { serverUrl } from "./app";
import axios from "axios";
import qs from "qs";

export function _fetchStudent(id) {
  return axios.get(serverUrl(), {
    params: {
      tag: "student",
      id: id,
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _fetchStudents() {
  return axios.get(serverUrl(), {
    params: {
      tag: "students",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _createStudent(student) {
  delete student.id;
  const data = {
    path: "create_student",
    student,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _updateStudent(student) {
  const data = {
    path: "update_student",
    student,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _removeStudent(id) {
  const data = {
    path: "remove_student",
    id,
  };
  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _uploadStudents(data) {
  return new Promise((resolve, reject) => {
    data.forEach((student) => {
      const data = {
        path: "create_student",
        student,
      };

      axios
        .post(serverUrl(), qs.stringify(data), {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
            authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  });
}

export function _fetchFullyPaidStudents() {
  return axios.get(serverUrl(), {
    params: {
      tag: "fully_paid_students",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _fetchOwingStudents() {
  return axios.get(serverUrl(), {
    params: {
      tag: "owing_students",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}
