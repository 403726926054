import { Link } from "react-router-dom";

const PageHeader = (props) => {
  return (
    <div className="row">
      <div className="col-12">
        <p className="fs-2 mb-0">{props.pageTitle}</p>
      </div>
      <ul className="nav" style={{ fontSize: "0.85em" }}>
        {props.crumbs.map((crumb, index) => (
          <li className="nav-item" key={index}>
            <Link to={crumb.path} className="nav-link active">
              {crumb.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PageHeader;
