import { _fetchStudentCoursePayments } from "../utils/course_payments";

export const FETCH_PAYMENT_DETAILS = "FETCH_PAYMENT_DETAILS";

export function fetchPaymentDetails(course_payments) {
  return {
    type: FETCH_PAYMENT_DETAILS,
    course_payments,
  };
}

export function handleFetchStudentCoursePayments(
  student_id,
  enrolled_course_id
) {
  return (dispatch) => {
    console.log(student_id, enrolled_course_id);
    return _fetchStudentCoursePayments(student_id, enrolled_course_id).then(
      (response) => {
        console.log(response);
        dispatch(fetchPaymentDetails(response.data.course_payments));
      }
    );
  };
}
