import { FETCH_COURSE_PAYMENTS } from "../actions/coursePayments";

export default function coursePayments(state = {}, action) {
  switch (action.type) {
    case FETCH_COURSE_PAYMENTS:
      return {
        ...action.course_payments,
      };
    default:
      return state;
  }
}
