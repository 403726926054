import { serverUrl } from "./app";
import axios from "axios";
import qs from "qs";

export function _fetchUsers() {
  return axios.get(serverUrl(), {
    params: {
      tag: "users",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _fetchUser(id) {
  return axios.get(serverUrl(), {
    params: {
      tag: "user",
      id,
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _createUser(user) {
  delete user.id;
  const data = {
    path: "create_user",
    user,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _updateUser(user) {
  const data = {
    path: "update_user",
    user,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _removeUser(id) {
  const data = {
    path: "remove_user",
    id,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}
