import { useState, useEffect } from "react";
import { Container, Row, Col, Accordion, Table } from "react-bootstrap";
import PageHeader from "../PageHeader";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { setStudentId } from "../../actions/student";
import { handleFetchEnrolledCourses } from "../../actions/enrolledCourses";
import { handleFetchCoursePayments } from "../../actions/coursePayments";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import VerticallyCenteredModal from "../UI/Modal";
import MakePayment from "./MakePayment";

const withRouter = (Component) => {
  const ComponentWithProp = (props) => {
    const params = useParams();
    return <Component {...props} router={{ params }} />;
  };

  return ComponentWithProp;
};

const CoursePayments = ({
  dispatch,
  id,
  student,
  enrolledCourses,
  coursePayments,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [coursePayment, setCoursePayment] = useState({});

  const modalHide = () => {
    setModalShow(false);
  };

  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Student Info",
      path: `/student/${id}`,
    },
  ];

  const makePayment = (event, coursePayment) => {
    event.preventDefault();
    setCoursePayment(coursePayment);
    setModalShow(true);
  };

  useEffect(() => {
    dispatch(setStudentId(id));
    dispatch(handleFetchEnrolledCourses());
    dispatch(handleFetchCoursePayments());
  }, [dispatch, id]);

  return (
    <Container style={{ paddingBottom: "100px" }}>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Course Payments"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row className="bg-light p-3 mx-0 mb-3">
        <div className="col-12">
          <p>Name: {`${student.surname} ${student.firstname}`}</p>
        </div>
        <div className="col-12">
          <p>Email: {student.email}</p>
        </div>
        <div className="col-12">
          <p>Phone: {student.phone}</p>
        </div>
      </Row>
      <Row>
        <Accordion defaultActiveKey="0">
          {enrolledCourses.map((enrolledCourse, index) => (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>{enrolledCourse.name}</Accordion.Header>
              <Accordion.Body>
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Amonut Due</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Amount Paid</th>
                      <th scope="col">Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {coursePayments
                      .filter(
                        (coursePayment) =>
                          coursePayment.enrolled_course_id === enrolledCourse.id
                      )
                      .map((coursePayment, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{coursePayment.amount_due}</td>
                          <td>{coursePayment.discount}</td>
                          <td>{coursePayment.amount_paid}</td>
                          <td>{coursePayment.balance}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-outline-success"
                    onClick={(event) =>
                      makePayment(
                        event,
                        coursePayments
                          .filter(
                            (coursePayment) =>
                              coursePayment.enrolled_course_id ===
                              enrolledCourse.id
                          )
                          .pop()
                      )
                    }
                  >
                    <span style={{ fontSize: "1em" }} className="mx-4">
                      <FontAwesomeIcon icon={faDollarSign} /> Pay
                    </span>
                  </button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Row>
      <VerticallyCenteredModal
        show={modalShow}
        onHide={modalHide}
        title={"Make payment"}
        body={
          <MakePayment
            dispatch={dispatch}
            coursePayment={coursePayment}
            hide={modalHide}
          />
        }
      />
    </Container>
  );
};

const mapStateToProps = (
  { students, enrolledCourses, coursePayments },
  otherProps
) => {
  const { id } = otherProps.router.params;

  const student = Object.values(students)
    .filter((student) => student.id === parseInt(id))
    .pop();

  enrolledCourses = Object.values(enrolledCourses);
  coursePayments = Object.values(coursePayments);

  console.log(enrolledCourses);

  return {
    id,
    student,
    enrolledCourses,
    coursePayments,
  };
};

export default withRouter(connect(mapStateToProps)(CoursePayments));
