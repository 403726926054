const Remove = ({ cancel, remove }) => {
  return (
    <div>
      <p>Do you want to remove this?</p>
      <div className="col-12 mt-3 d-flex justify-content-end">
        <button
          id="cancel"
          className="btn btn-block btn-secondary me-3"
          onClick={cancel}
        >
          Cancel
        </button>
        <button
          id="remove"
          className="btn btn-block btn-danger"
          onClick={remove}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default Remove;
