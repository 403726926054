import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import ActionButtons from "../ActionButtons";
import PageHeader from "../PageHeader";
import { handleRemoveCourse } from "../../actions/courses";

const Courses = ({ dispatch, courses }) => {
  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "New Course",
      path: "/courses/new",
    },
    {
      name: "Bulk Upload",
      path: "/courses/bulk_upload",
    },
  ];

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Courses"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row>
        <Col>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Duration</th>
                <th scope="col">Factor</th>
                <th scope="col">Cost</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{course.name}</td>
                  <td>{course.duration}</td>
                  <td>{course.factor}</td>
                  <td>{course.cost}</td>
                  <td>
                    <ActionButtons
                      dispatch={dispatch}
                      remove={handleRemoveCourse}
                      resource={"course"}
                      record={course}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ courses }) => {
  const allCourses = Object.entries(courses)
    .map((course) => course.pop())
    .sort((a, b) => {
      return parseInt(a.id) - parseInt(b.id);
    });

  return {
    courses: allCourses,
  };
};

export default connect(mapStateToProps)(Courses);
