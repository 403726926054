import { _fetchStudent } from "../utils/students";

export const FETCH_STUDENT = "FETCH_STUDENT";
export const SET_STUDENT_ID = "SET_STUDENT_ID";

export function setStudentId(id) {
  return {
    type: SET_STUDENT_ID,
    id,
  };
}
