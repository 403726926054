import { FETCH_ENROLLED_COURSES } from "../actions/enrolledCourses";

export default function enrolledCourses(state = {}, action) {
  switch (action.type) {
    case FETCH_ENROLLED_COURSES:
      return {
        ...action.enrolled_courses,
      };
    default:
      return state;
  }
}
