import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { handleFetchStudentCoursePayments } from "../../actions/paymentDetails";
import PageHeader from "../PageHeader";

const withRouter = (Component) => {
  const ComponentWithProp = (props) => {
    const params = useParams();
    return <Component {...props} router={{ params }} />;
  };

  return ComponentWithProp;
};

const PaymentDetails = ({
  dispatch,
  student_id,
  enrolled_course_id,
  paymentDetails,
}) => {
  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Students",
      path: "/students",
    },
    {
      name: "Courses",
      path: "/courses",
    },
  ];

  useEffect(() => {
    dispatch(handleFetchStudentCoursePayments(student_id, enrolled_course_id));
  }, [dispatch, student_id, enrolled_course_id]);

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Payment Details"} crumbs={crumbs} />
        </Col>
      </Row>
      {/* <Row>
        <Col>
          <p>
            {paymentDetails.length > 0 && paymentDetails.pop().student_name}
          </p>
          <p>{paymentDetails.pop().course_name}</p>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Amount Due</th>
                <th scope="col">Discount</th>
                <th scope="col">Amount Paid</th>
                <th scope="col">Balance</th>
                {/* <th scope="col">Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {paymentDetails.map((paymentDetail, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{paymentDetail.amount_due}</td>
                  <td>{paymentDetail.discount}</td>
                  <td>{paymentDetail.amount_paid}</td>
                  <td>{paymentDetail.balance}</td>
                  {/* <td>
                    <Link to={"/"}>Details</Link>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ paymentDetails }, otherProps) => {
  const { student_id, enrolled_course_id } = otherProps.router.params;

  return {
    student_id,
    enrolled_course_id,
    paymentDetails: Object.values(paymentDetails),
  };
};

export default withRouter(connect(mapStateToProps)(PaymentDetails));
