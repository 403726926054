import { SET_STUDENT_ID } from "../actions/student";

export default function students(state = {}, action) {
  switch (action.type) {
    case SET_STUDENT_ID:
      state = { id: action.id };
      return {
        ...state,
      };
    default:
      return state;
  }
}
