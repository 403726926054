import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from "../PageHeader";
import { connect } from "react-redux";
import { handleNewCourse, handleUpdateCourse } from "../../actions/courses";
import { useNavigate, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const withRouter = (Component) => {
  const ComponentWithProp = (props) => {
    const params = useParams();
    return <Component {...props} router={{ params }} />;
  };

  return ComponentWithProp;
};

const Course = ({ dispatch, course }) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const schema = yup.object().shape({
    name: yup.string().required(),
    duration: yup.number().required().min(1).positive().round("trunc"),
    factor: yup.string().required(),
    cost: yup.number().required().min(1).positive(),
  });

  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Courses",
      path: "/courses",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    if (id !== "new") {
      data.id = parseInt(id);
      dispatch(handleUpdateCourse(data));
    } else {
      dispatch(handleNewCourse(data));
    }
    navigate("/courses");
  };

  useEffect(() => {
    if (course && id !== "new") {
      setValue("name", course.name);
      setValue("duration", course.duration);
      setValue("factor", course.factor);
      setValue("cost", course.cost);
    }
  }, [course, id, setValue]);

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Course"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs lg={6}>
          <form
            id="course-form"
            className="row"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="col-md-12 mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                name={"name"}
                className={"form-control"}
                {...register("name")}
              />
              <span className="text-danger">{errors.name?.message}</span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Duration</label>
              <input
                type="text"
                name="duration"
                className={"form-control"}
                {...register("duration")}
              />
              <span className="text-danger">{errors.duration?.message}</span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Factor</label>
              <select
                name="factor"
                className={"form-select"}
                {...register("factor")}
              >
                <option value="">Select factor</option>
                <option value="Days">Days</option>
                <option value="Weeks">Weeks</option>
              </select>
              <span className="text-danger">{errors.factor?.message}</span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Cost</label>
              <input
                type="number"
                name="cost"
                className={"form-control"}
                {...register("cost")}
              />
              <span className="text-danger">{errors.cost?.message}</span>
            </div>
            <div className="col-12 mt-3 d-flex justify-content-end">
              <button
                id="btnAdd"
                type="submit"
                className="btn btn-block btn-success"
              >
                Submit
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ courses }, otherProps) => {
  const { id } = otherProps.router.params;

  let course;

  if (id) {
    course = Object.values(courses)
      .filter((course) => course.id === parseInt(id))
      .pop();
  } else {
    course = {
      date_of_enrollment: "",
      surname: "",
      firstname: "",
      gender: "",
      date_of_birth: "",
      email: "",
      phone: "",
    };
  }

  return {
    course,
  };
};

export default withRouter(connect(mapStateToProps)(Course));
