import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import ActionButtons from "../ActionButtons";
import PageHeader from "../PageHeader";
import moment from "moment";
import { handleRemoveSchedule } from "../../actions/schedules";

const Schedules = ({ dispatch, schedules }) => {
  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "New Schedule",
      path: "/schedules/new",
    },
  ];

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Schedules"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row>
        <Col>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Staff Name</th>
                <th scope="col">Course</th>
                <th scope="col">Date</th>
                <th scope="col">Duration</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {schedules.map((schedule, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{schedule.staff_name}</td>
                  <td>{schedule.course_name}</td>
                  <td>{new Date(schedule.start_date).toDateString()}</td>
                  <td>{schedule.duration}</td>
                  <td>
                    <ActionButtons
                      dispatch={dispatch}
                      remove={handleRemoveSchedule}
                      resource={"schedule"}
                      record={schedule}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ schedules }) => {
  const allSchedules = Object.entries(schedules)
    .map((schedule) => schedule.pop())
    .sort((a, b) => {
      return parseInt(a.id) - parseInt(b.id);
    });

  // moment.duration({
  //   seconds: 2,
  //   minutes: 2,
  //   hours: 2,
  //   days: 2,
  //   weeks: 2,
  //   months: "2",
  //   years: "2",
  // });

  allSchedules.forEach((schedule) => {
    const [startHours, startMinutes] = schedule.start.split(":");
    const [endHours, endMinutes] = schedule.end.split(":");

    var start = moment.duration({ hours: startHours, minutes: startMinutes });
    var end = moment.duration({ hours: endHours, minutes: endMinutes });

    const time = end.subtract(start);

    schedule.duration = `${time.hours()} Hour ${time.minutes()} Minutes`;
  });

  return {
    schedules: allSchedules,
  };
};

export default connect(mapStateToProps)(Schedules);
