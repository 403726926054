import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const ActionButtons = (props) => {
  return (
    <div>
      <Link to={`/${props.resource}/${props.record.id}`} className="me-3">
        <FontAwesomeIcon icon={faInfo} className="fa-lg" color="green" />
      </Link>
      <Link to={`/${props.resource}s/${props.record.id}`} className="me-3">
        <FontAwesomeIcon icon={faEdit} className="fa-lg" color="blue" />
      </Link>
      <span
        to="/"
        className="me-3"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.dispatch(props.remove(props.record));
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} className="fa-lg" color="Tomato" />
      </span>
    </div>
  );
};

export default ActionButtons;
