import { serverUrl } from "./app";
import axios from "axios";
import qs from "qs";

export function _fetchCourses() {
  return axios.get(serverUrl(), {
    params: {
      tag: "courses",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _createCourse(course) {
  delete course.id;
  const data = {
    path: "create_course",
    course,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _updateCourse(course) {
  const data = {
    path: "update_course",
    course,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _removeCourse(id) {
  const data = {
    path: "remove_course",
    id,
  };
  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}
