import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import ActionButtons from "../ActionButtons";
import PageHeader from "../PageHeader";
import { handleRemoveUser } from "../../actions/users";

const Users = ({ dispatch, users }) => {
  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "New User",
      path: "/users/new",
    },
  ];

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Users"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row>
        <Col>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Role</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.role}</td>
                  <td>
                    <ActionButtons
                      dispatch={dispatch}
                      remove={handleRemoveUser}
                      resource={"user"}
                      record={user}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ users }) => {
  const allUsers = Object.entries(users)
    .map((user) => user.pop())
    .sort((a, b) => {
      return parseInt(a.id) - parseInt(b.id);
    });

  return {
    users: allUsers,
  };
};

export default connect(mapStateToProps)(Users);
