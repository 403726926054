import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { connect } from "react-redux";
import { handleAddEnrolledCourse } from "../../actions/enrolledCourses";

const EnrolledCourse = ({ dispatch, courses, student_id, action, hide }) => {
  const schema = yup.object().shape({
    date_enrolled: yup.string().required(),
    course_id: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.student_id = student_id;

    const course = Object.values(courses)
      .filter((course) => course.id === parseInt(data.course_id))
      .pop();

    const coursePayment = {
      amount_due: course.cost,
      discount: 0,
      amount_paid: 0,
      balance: course.cost,
    };

    console.log(action);

    if (action === "new") {
      dispatch(handleAddEnrolledCourse(data, coursePayment));
      hide();
    } else {
      // dispatch(handleNewStudent(data));
    }
  };

  return (
    <form
      id="enroll-course-form"
      className="row"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="col-12 mb-3" v-if="id === 'new'">
        <label className="form-label">Course Name</label>
        <select
          name="course_id"
          className={"form-select"}
          {...register("course_id")}
        >
          <option value="">Select a course</option>
          {Object.values(courses).map((course, index) => (
            <option value={course.id} key={index}>
              {course.name}
            </option>
          ))}
        </select>
        <span className="text-danger">{errors.course_id?.message}</span>
      </div>
      <div className="col-12 mb-3">
        <label className="form-label">Date Of Enrollment</label>
        <input
          type="date"
          name="date_enrolled"
          className={"form-control"}
          {...register("date_enrolled")}
        />
        <span className="text-danger">{errors.date_enrolled?.message}</span>
      </div>
      <div className="col-12 mt-3 d-flex justify-content-end">
        <button id="btnAdd" type="submit" className="btn btn-block btn-success">
          Submit
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = ({ courses }) => ({
  courses,
});

export default connect(mapStateToProps)(EnrolledCourse);
