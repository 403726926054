import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import ActionButtons from "../ActionButtons";
import PageHeader from "../PageHeader";
import { handleRemoveStudent } from "../../actions/students";

const Students = ({ dispatch, students }) => {
  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "New Student",
      path: "/students/new",
    },
    {
      name: "Bulk Upload",
      path: "/students/bulk_upload",
    },
  ];

  const search = (event) => {
    event.preventDefault();
  };

  const paginationReset = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Students"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row>
        <Col className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Input name, phone or email"
            aria-label="Search"
            aria-describedby="search-by-name"
            v-model="search_params"
          />
          <div className="input-group-append" id="button-addon4">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={search}
            >
              Search
            </button>
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={paginationReset}
            >
              Reset
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={student.id}>
                  <td>{index + 1}</td>
                  <td>{`${student.surname} ${student.firstname}`}</td>
                  <td>{student.email}</td>
                  <td>{student.phone}</td>
                  <td>
                    <ActionButtons
                      dispatch={dispatch}
                      remove={handleRemoveStudent}
                      resource={"student"}
                      record={student}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ students }) => {
  const allStudents = Object.entries(students)
    .map((student) => student.pop())
    .sort((a, b) => {
      return parseInt(a.id) - parseInt(b.id);
    });

  return {
    students: allStudents,
  };
};

export default connect(mapStateToProps)(Students);
