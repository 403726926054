import { FETCH_PAYMENT_DETAILS } from "../actions/paymentDetails";

export default function paymentDetails(state = {}, action) {
  switch (action.type) {
    case FETCH_PAYMENT_DETAILS:
      return {
        ...action.course_payments,
      };
    default:
      return state;
  }
}
