import {
  FETCH_COURSES,
  UPDATE_COURSE,
  NEW_COURSE,
  REMOVE_COURSE,
} from "../actions/courses";

export default function courses(state = {}, action) {
  switch (action.type) {
    case FETCH_COURSES:
      return {
        ...state,
        ...action.courses,
      };
    case NEW_COURSE:
      return {
        ...state,
        [Object.keys(state).length]: action.course,
      };
    case UPDATE_COURSE:
      state = Object.values(state).filter(
        (course) => course.id !== parseInt(action.course.id)
      );
      state.push(action.course);
      return {
        ...state,
      };
    case REMOVE_COURSE:
      state = Object.values(state).filter((course) => course.id !== action.id);
      return {
        ...state,
      };
    default:
      return state;
  }
}
