const NotFound = () => {
  return (
    <div className="container text-center">
      <h1 style={{ fontSize: "8em" }}>404</h1>
      <p>OOPS! NOTHING WAS FOUND</p>
    </div>
  );
};

export default NotFound;
