import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  faCalendarAlt,
  faCubes,
  faGraduationCap,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import DashboardMenuBarItem from "./DashboardMenuBarItem";
import PageHeader from "../components/PageHeader";

const Dashboard = ({
  dispatch,
  authedUser,
  students,
  courses,
  paymentReport,
}) => {
  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Dashboard"} crumbs={[]} />
          <h6>{authedUser.email}</h6>
        </Col>
      </Row>
      <Row className="mb-3 bg-black pt-3 mx-0">
        <Col xs={3} className="text-center">
          <DashboardMenuBarItem icon={faUsers} name={"Users"} path={"/users"} />
        </Col>
        <Col xs={3} className="text-center">
          <DashboardMenuBarItem
            icon={faGraduationCap}
            name={"Students"}
            path={"/students"}
          />
        </Col>
        <Col xs={3} className="text-center">
          <DashboardMenuBarItem
            icon={faCubes}
            name={"Courses"}
            path={"/courses"}
          />
        </Col>
        <Col xs={3} className="text-center">
          <DashboardMenuBarItem
            icon={faCalendarAlt}
            name={"Schedules"}
            path={"/schedules"}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg={3}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Students</h5>
              <p className="card-text display-4 text-muted">
                {Object.keys(students).length}
              </p>
              <Link to="/students">Details</Link>
            </div>
          </div>
        </Col>
        <Col xs="12" lg={3}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Courses</h5>
              <p className="card-text display-4 text-muted">
                {Object.keys(courses).length}
              </p>
              <Link to="/courses">Details</Link>
            </div>
          </div>
        </Col>
        <Col xs="12" lg={3}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Fully Paid Students</h5>
              <p className="card-text display-4 text-muted">
                {
                  Object.values(paymentReport).filter(
                    (item) =>
                      item.total_discount + item.total_paid === item.amount_due
                  ).length
                }
              </p>
              <Link to="/fully_paid">Details</Link>
            </div>
          </div>
        </Col>
        <Col xs="12" lg={3}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Owing Students</h5>
              <p className="card-text display-4 text-muted">
                {
                  Object.values(paymentReport).filter(
                    (item) =>
                      item.total_discount + item.total_paid !== item.amount_due
                  ).length
                }
              </p>
              <Link to="/owing">Details</Link>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12" lg={6}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Course Progress</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                0 student Completing in 1 week
              </h6>
              <p className="card-text">WIP</p>
              <Link to="/courses_in_progress">Details</Link>
            </div>
          </div>
        </Col>
        <Col xs="12" lg={6}>
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Certificates</h5>
              <h6 className="card-subtitle mb-2 text-muted">
                0 certificates pending
              </h6>
              <p className="card-text">WIP</p>
              <Link to="/courses_in_progress">Details</Link>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ authedUser, students, courses, paymentReport }) => {
  return {
    authedUser,
    students,
    courses,
    paymentReport,
  };
};

export default connect(mapStateToProps)(Dashboard);
