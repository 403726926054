import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from "../PageHeader";
import moment from "moment";
import { connect } from "react-redux";
import { handleNewStudent, handleUpdateStudent } from "../../actions/students";
import { useNavigate, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const withRouter = (Component) => {
  const ComponentWithProp = (props) => {
    const params = useParams();
    return <Component {...props} router={{ params }} />;
  };

  return ComponentWithProp;
};

const Student = ({ dispatch, student }) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const schema = yup.object().shape({
    date_of_enrollment: yup.string().required(),
    surname: yup.string().required(),
    firstname: yup.string().required(),
    gender: yup.string().required(),
    date_of_birth: yup.string().required(),
    email: yup.string().required().email(),
    phone: yup.string().required().min(13).max(13),
  });

  const formatDate = (inputDate) => {
    return moment(new Date(inputDate)).format("yyyy-MM-DD");
  };

  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Students",
      path: "/students",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (id !== "new") {
      data.id = parseInt(id);
      dispatch(handleUpdateStudent(data));
    } else {
      dispatch(handleNewStudent(data));
    }
    navigate("/students");
  };

  useEffect(() => {
    if (student && id !== "new") {
      setValue("date_of_enrollment", formatDate(student.date_of_enrollment));
      setValue("surname", student.surname);
      setValue("firstname", student.firstname);
      setValue("gender", student.gender);
      setValue("date_of_birth", formatDate(student.date_of_birth));
      setValue("phone", student.phone);
      setValue("email", student.email);
    }
  }, [student, id, setValue]);

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Student"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs lg={6}>
          <form
            id="student-form"
            className="row"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="col-md-12 mb-3">
              <label className="form-label">Date Of Enrollment</label>
              <input
                type="date"
                name="date_of_enrollment"
                className={"form-control"}
                {...register("date_of_enrollment")}
              />
              <span className="text-danger">
                {errors.date_of_enrollment?.message}
              </span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                name={"surname"}
                className={"form-control"}
                {...register("surname")}
              />
              <span className="text-danger">{errors.surname?.message}</span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">First Name</label>
              <input
                type="text"
                name="firstname"
                className={"form-control"}
                {...register("firstname")}
              />
              <span className="text-danger">{errors.firstname?.message}</span>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Gender</label>
              <select
                name="gender"
                className={"form-select"}
                {...register("gender")}
              >
                <option value="">Select Gender</option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
              </select>
              <span className="text-danger">{errors.gender?.message}</span>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Date Of Birth</label>
              <input
                type="date"
                name="date_of_birth"
                className={"form-control"}
                {...register("date_of_birth")}
              />
              <span className="text-danger">
                {errors.date_of_birth?.message}
              </span>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Phone</label>
              <input
                type="text"
                name="phone"
                className={"form-control"}
                {...register("phone")}
              />
              <span className="text-danger">{errors.phone?.message}</span>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                name="email"
                className={"form-control"}
                {...register("email")}
              />
              <span className="text-danger">{errors.email?.message}</span>
            </div>
            <div className="col-12 mt-3 d-flex justify-content-end">
              <button
                id="btnAdd"
                type="submit"
                className="btn btn-block btn-success"
              >
                Submit
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ students }, otherProps) => {
  const { id } = otherProps.router.params;

  let student;

  if (id) {
    student = Object.values(students)
      .filter((student) => student.id === parseInt(id))
      .pop();
  } else {
    student = {
      date_of_enrollment: "",
      surname: "",
      firstname: "",
      gender: "",
      date_of_birth: "",
      email: "",
      phone: "",
    };
  }

  return {
    student,
  };
};

export default withRouter(connect(mapStateToProps)(Student));
