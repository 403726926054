import { _fetchStudents } from "../utils/students";
import { _fetchCourses } from "../utils/courses";
import { _fetchUsers } from "../utils/users";
import { _fetchSchedules } from "../utils/schedules";
import { _fetchPaymentReport } from "../utils/course_payments";
import { fetchStudents } from "./students";
import { fetchCourses } from "./courses";
import { fetchUsers } from "./users";
import { fetchSchedules } from "./schedules";
import { fetchPaymentReport } from "./paymentReport";

export function handleInitialData() {
  return async (dispatch) => {
    const students = await _fetchStudents();
    const courses = await _fetchCourses();
    const users = await _fetchUsers();
    const schedules = await _fetchSchedules();
    const paymentReport = await _fetchPaymentReport();

    dispatch(fetchStudents(students.data.students));
    dispatch(fetchCourses(courses.data.courses));
    dispatch(fetchUsers(users.data.users));
    dispatch(fetchSchedules(schedules.data.schedules));
    dispatch(fetchPaymentReport(paymentReport.data.students));
  };
}
