import { useEffect } from "react";
import { handleInitialData } from "../actions/shared";
import "../assets/css/App.css";
import { connect } from "react-redux";
import NavBar from "./NavBar";
import Dashboard from "./Dashboard";
import Login from "./Login";
import NotFound from "./NotFound";
import Students from "./Student/Students";
import Student from "./Student/Student";
import StudentInfo from "./Student/StudentInfo";
import { Routes, Route } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import { handleShowToast } from "../actions/toast";
import CoursePayments from "./CoursePayment/CoursePayments";
import Courses from "./Course/Courses";
import Course from "./Course/Course";
import Users from "./User/Users";
import User from "./User/User";
import Schedules from "./Schedule/Schedules";
import Schedule from "./Schedule/Schedule";
import FullyPaidStudents from "./CoursePayment/FullyPaidStudents";
import OwingStudents from "./CoursePayment/OwingStudents";
import PaymentDetails from "./CoursePayment/PaymentDetails";

const App = ({ dispatch, authedUser, toast }) => {
  useEffect(() => {
    dispatch(handleInitialData());
  }, [dispatch]);

  return (
    <div>
      {authedUser === null ? (
        <Login />
      ) : (
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", top: "56px", right: "0px" }}>
            {toast && (
              <Toast
                onClose={() =>
                  dispatch(
                    handleShowToast({ show: false, title: "", message: "" })
                  )
                }
                show={toast.show}
                delay={10000}
                autohide
                className={`text-bg-${toast.bg}`}
              >
                <Toast.Header>
                  <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                  />
                  <strong className="me-auto">{toast.title}</strong>
                </Toast.Header>
                <Toast.Body>{toast.message}</Toast.Body>
              </Toast>
            )}
          </div>
          <NavBar />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" exact element={<Dashboard />} />
            <Route path="/users" exact element={<Users />} />
            <Route path="/users/:id" exact element={<User />} />
            <Route path="/students" exact element={<Students />} />
            <Route path="/students/:id" exact element={<Student />} />
            <Route path="/student/:id" exact element={<StudentInfo />} />
            <Route path="/courses" exact element={<Courses />} />
            <Route path="/courses/:id" exact element={<Course />} />
            <Route
              path="/course_payments/:id"
              exact
              element={<CoursePayments />}
            />
            <Route path="/schedules" exact element={<Schedules />} />
            <Route path="/schedules/:id" exact element={<Schedule />} />
            <Route path="/fully_paid" exact element={<FullyPaidStudents />} />
            <Route path="/owing" exact element={<OwingStudents />} />
            <Route
              path="/payment_details/:student_id/:enrolled_course_id"
              exact
              element={<PaymentDetails />}
            />
          </Routes>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ authedUser, toast }) => ({
  authedUser,
  toast,
});

export default connect(mapStateToProps)(App);
