import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { handlePayment } from "../../actions/coursePayments";

const MakePayment = ({ dispatch, coursePayment, hide }) => {
  const schema = yup.object().shape({
    amount_due: yup.string().required(),
    amount_paid: yup.string().required(),
    discount: yup.string().required(),
    balance: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAmountPaid = (e) => {
    let amountPaid = parseInt(e.target.value) || 0;
    setValue(
      "balance",
      parseInt(getValues("amount_due")) -
        (amountPaid + parseInt(getValues("discount")))
    );
    if (e.target.value === "") {
      setTimeout(() => {
        if (e.target.value === "") {
          e.target.value = 0;
        }
      }, 2000);
    }
  };

  const handleDiscount = (e) => {
    let discount = parseInt(e.target.value) || 0;
    setValue(
      "balance",
      parseInt(
        parseInt(getValues("amount_due")) - parseInt(getValues("amount_paid"))
      ) - discount
    );
    if (e.target.value === "") {
      setTimeout(() => {
        if (e.target.value === "") {
          e.target.value = 0;
        }
      }, 2000);
    }
  };

  const onSubmit = (data) => {
    data.enrolled_course_id = coursePayment.enrolled_course_id;
    data.date_of_payment = new Date();

    dispatch(handlePayment(data));

    hide();
  };

  useEffect(() => {
    setValue("amount_due", coursePayment.balance);
    setValue("amount_paid", 0);
    setValue("discount", 0);
    setValue("balance", 0);
  }, [setValue]);

  return (
    <form className="form-row" onSubmit={handleSubmit(onSubmit)}>
      <div className="col-12">
        <label className="form-label">Course</label>
        <p className="lead">{coursePayment.name}</p>
      </div>
      <div className="col-12 mb-3">
        <label className="form-label">Amount Due</label>
        <input
          type="text"
          name="amount_due"
          className={"form-control"}
          {...register("amount_due")}
        />
        <span className="text-danger">{errors.amount_due?.message}</span>
      </div>
      <div className="col-12 mb-3">
        <label className="form-label">Amount Paid</label>
        <input
          type="text"
          name="amount_paid"
          className={"form-control"}
          {...register("amount_paid", {
            onChange: (event) => handleAmountPaid(event),
          })}
        />
        <span className="text-danger">{errors.amount_paid?.message}</span>
      </div>
      <div className="col-12 mb-3">
        <label className="form-label">Discount</label>
        <input
          type="text"
          name="discount"
          className={"form-control"}
          {...register("discount", {
            onChange: (event) => handleDiscount(event),
          })}
        />
        <span className="text-danger">{errors.discount?.message}</span>
      </div>
      <div className="col-12 mb-3">
        <label className="form-label">Balance</label>
        <input
          type="text"
          name="balance"
          className={"form-control"}
          {...register("balance", { disabled: true })}
        />
        <span className="text-danger">{errors.balance?.message}</span>
      </div>
      <div className="d-flex justify-content-end">
        <button type="submit" className="btn btn-outline-success">
          Pay
        </button>
      </div>
    </form>
  );
};

export default MakePayment;
