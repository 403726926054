import { serverUrl } from "./app";
import axios from "axios";
import qs from "qs";

export function _fetchEnrolledCourses(student_id) {
  return axios.get(serverUrl(), {
    params: {
      tag: "enrolled_courses",
      student_id: student_id,
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _fetchEnrolledCourse(id) {
  return axios.get(serverUrl(), {
    params: {
      tag: "enrolled_course",
      id: id,
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _createEnrolledCourse(enrolled_course, course_payment) {
  const data = {
    path: "create_enrolled_course",
    enrolled_course,
    course_payment,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _updateEnrolledCourse(enrolled_course) {
  const data = {
    path: "update_enrolled_course",
    enrolled_course,
  };
  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _removeEnrolledCourse(id) {
  const data = {
    path: "remove_enrolled_course",
    id,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}
