import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from "../PageHeader";
import { connect } from "react-redux";
import {
  handleNewSchedule,
  handleUpdateSchedule,
} from "../../actions/schedules";
import { useNavigate, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";

const withRouter = (Component) => {
  const ComponentWithProp = (props) => {
    const params = useParams();
    return <Component {...props} router={{ params }} />;
  };

  return ComponentWithProp;
};

const Schedule = ({ dispatch, courses, users, schedule }) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const schema = yup.object().shape({
    user_id: yup.string().required(),
    course_id: yup.string().required(),
    start_date: yup.string().required(),
    start: yup.string().required(),
    end: yup.string().required(),
  });

  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Schedules",
      path: "/schedules",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // console.log(data);

    const staff_name = users
      .filter((user) => user.id === parseInt(data.user_id))
      .pop().name;

    const course_name = courses
      .filter((course) => course.id === parseInt(data.course_id))
      .pop().name;

    data.staff_name = staff_name;
    data.course_name = course_name;

    if (id !== "new") {
      data.id = parseInt(id);
      dispatch(handleUpdateSchedule(data));
    } else {
      dispatch(handleNewSchedule(data));
    }
    navigate("/schedules");
  };

  useEffect(() => {
    if (schedule && id !== "new") {
      setValue("user_id", schedule.user_id);
      setValue("course_id", schedule.course_id);
      setValue(
        "start_date",
        moment(new Date(schedule.start_date)).format("yyyy-MM-DD")
      );
      setValue("start", schedule.start);
      setValue("end", schedule.end);
    }
  }, [schedule, id, setValue]);

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Schedule"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs lg={6}>
          <form
            id="course-form"
            className="row"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="col-md-12 mb-3">
              <label className="form-label">Staff Name</label>
              <select
                name="user_id"
                className={"form-select"}
                {...register("user_id")}
              >
                <option value="">Select staff</option>
                {users.map((user, index) => (
                  <option value={user.id} key={index}>
                    {user.name}
                  </option>
                ))}
              </select>
              <span className="text-danger">{errors.user_id?.message}</span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Course Name</label>
              <select
                name="course_id"
                className={"form-select"}
                {...register("course_id")}
              >
                <option value="">Select course</option>
                {courses.map((course, index) => (
                  <option value={course.id} key={index}>
                    {course.name}
                  </option>
                ))}
              </select>
              <span className="text-danger">{errors.course_id?.message}</span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Start Date</label>
              <input
                type="date"
                name="start_date"
                className={"form-control"}
                {...register("start_date")}
              />
              <span className="text-danger">{errors.start_date?.message}</span>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Start</label>
              <input
                type="time"
                name="start"
                className={"form-control"}
                {...register("start")}
              />
              <span className="text-danger">{errors.start?.message}</span>
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">End</label>
              <input
                type="time"
                name="end"
                className={"form-control"}
                {...register("end")}
              />
              <span className="text-danger">{errors.end?.message}</span>
            </div>
            <div className="col-12 mt-3 d-flex justify-content-end">
              <button
                id="btnAdd"
                type="submit"
                className="btn btn-block btn-success"
              >
                Submit
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ users, courses, schedules }, otherProps) => {
  const { id } = otherProps.router.params;

  let schedule;

  if (id) {
    schedule = Object.values(schedules)
      .filter((schedule) => schedule.id === parseInt(id))
      .pop();
  } else {
    schedule = {
      name: "",
      email: "",
      phone: "",
      role: "",
    };
  }

  return {
    users: Object.values(users),
    courses: Object.values(courses),
    schedule,
  };
};

export default withRouter(connect(mapStateToProps)(Schedule));
