import { _createUser, _updateUser, _removeUser } from "../utils/users";
import { handleShowToast } from "./toast";

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USER = "FETCH_USER";
export const NEW_USER = "NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const REMOVE_USER = "REMOVE_USER";

export function fetchUsers(users) {
  return {
    type: FETCH_USERS,
    users,
  };
}

export function fetchUser(user) {
  return {
    type: FETCH_USER,
    user,
  };
}

export function newUser(user) {
  return {
    type: NEW_USER,
    user,
  };
}

export function handleNewUser(user) {
  return (dispatch) => {
    return _createUser(user).then((response) => {
      if (response.status === 200) {
        user.id = response.data.id;
        dispatch(newUser(user));
        dispatch(
          handleShowToast({
            show: true,
            title: "User",
            message: response.data.message,
            bg: "success",
          })
        );
      } else {
      }
    });
  };
}

export function updateUser(user) {
  return {
    type: UPDATE_USER,
    user,
  };
}

export function handleUpdateUser(user) {
  return (dispatch) => {
    return _updateUser(user).then((response) => {
      console.log(response);

      dispatch(updateUser(user));
      dispatch(
        handleShowToast({
          show: true,
          title: "User",
          message: response.data.message,
          bg: "success",
        })
      );
    });
  };
}

export function removeUser(id) {
  return {
    type: REMOVE_USER,
    id,
  };
}

export function handleRemoveUser(user) {
  return (dispatch) => {
    return _removeUser(user.id).then((response) => {
      if (response.status === 200) {
        dispatch(removeUser(user.id));
        dispatch(
          handleShowToast({
            show: true,
            title: "User",
            message: response.data.message,
            bg: "success",
          })
        );
      }
    });
  };
}
