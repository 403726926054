import {
  FETCH_USERS,
  FETCH_USER,
  UPDATE_USER,
  NEW_USER,
  REMOVE_USER,
} from "../actions/users";

export default function users(state = {}, action) {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        ...action.users,
      };
    case FETCH_USER:
      return {
        ...state,
        ...action.user,
      };
    case NEW_USER:
      return {
        ...state,
        [Object.keys(state).length]: action.user,
      };
    case UPDATE_USER:
      state = Object.values(state).filter(
        (user) => user.id !== parseInt(action.user.id)
      );
      state.push(action.user);
      return {
        ...state,
      };
    case REMOVE_USER:
      state = Object.values(state).filter((user) => user.id !== action.id);
      return {
        ...state,
      };
    default:
      return state;
  }
}
