import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from "../PageHeader";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  handleFetchEnrolledCourses,
  handleRemoveEnrolledCourse,
} from "../../actions/enrolledCourses";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import VerticallyCenteredModal from "../UI/Modal";
import EnrolledCourse from "../Course/EnrolledCourse";
import { setStudentId } from "../../actions/student";
import Remove from "../UI/Remove";

const withRouter = (Component) => {
  const ComponentWithProp = (props) => {
    const params = useParams();
    return <Component {...props} router={{ params }} />;
  };

  return ComponentWithProp;
};

const StudentInfo = ({ dispatch, student, id, enrolledCourses }) => {
  const [enrolledCourseId, setEnrolledCourseId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [action, setAction] = useState("");

  const modalHide = () => {
    setModalShow(false);
  };

  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Students",
      path: "/students",
    },
    {
      name: "Course Payments",
      path: `/course_payments/${id}`,
    },
  ];

  const startDate = (enrolled_course) => {
    return moment(enrolled_course.date_enrolled);
  };

  const endDate = (enrolled_course) => {
    return moment(enrolled_course.date_enrolled).add(
      enrolled_course.duration,
      enrolled_course.factor.toLowerCase()
    );
  };

  const handleInitializeCreate = (event) => {
    event.preventDefault();

    setAction("new");

    setModalShow(true);
  };

  const handleInitializeUpdate = (event, id) => {
    event.preventDefault();
    setEnrolledCourseId(id);
    setAction("update");
    setModalShow(true);
  };

  const handleInitializeRemove = (event, id) => {
    event.preventDefault();
    setEnrolledCourseId(id);
    setAction("remove");
    setModalShow(true);
  };

  const remove = () => {
    console.log("Remove");
    dispatch(handleRemoveEnrolledCourse(enrolledCourseId));
    modalHide();
  };

  useEffect(() => {
    dispatch(setStudentId(id));
    dispatch(handleFetchEnrolledCourses());
  }, [dispatch, id]);

  return (
    <Container style={{ paddingBottom: "100px" }}>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Student Info"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row className="bg-light p-3 mx-0 mb-3">
        <div className="col-12">
          <p>Name: {`${student.surname} ${student.firstname}`}</p>
        </div>
        <div className="col-12">
          <p>Email: {student.email}</p>
        </div>
        <div className="col-12">
          <p>Phone: {student.phone}</p>
        </div>
      </Row>
      <Row>
        <div className="col-12">
          <span className="h4">Enrolled Courses</span>
          <span className="mx-2">|</span>
          <span
            style={{
              fontSize: "1em",
              color: "blue",
              cursor: "pointer",
            }}
            className="me-3"
            onClick={(event) => handleInitializeCreate(event)}
          >
            Add
          </span>
        </div>
        <div className="col-12 mt-2">
          {enrolledCourses.map((enrolled_course, index) => (
            <div className="card w-100 mb-2" key={index}>
              <div className="card-body">
                <h5 className="card-title">{enrolled_course.name}</h5>
                <p className="card-text">
                  {enrolled_course.duration} {enrolled_course.factor}
                </p>
                <p className="card-text">
                  Date Enrolled:{" "}
                  {new Date(enrolled_course.date_enrolled).toDateString()}
                </p>
                <p className="card-text">
                  Ends: {new Date(endDate(enrolled_course)).toDateString()}
                </p>
                <p className="card-text">
                  Certificate: {enrolled_course.certificate}
                </p>
                <div className="d-flex justify-content-end">
                  <span
                    style={{
                      fontSize: "1em",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    className="me-3"
                    onClick={(event) =>
                      handleInitializeUpdate(event, enrolled_course.id)
                    }
                  >
                    <FontAwesomeIcon icon={faEdit} className="fa-lg" />
                  </span>
                  <span
                    style={{
                      fontSize: "1em",
                      color: "Tomato",
                      cursor: "pointer",
                    }}
                    onClick={(event) =>
                      handleInitializeRemove(event, enrolled_course.id)
                    }
                  >
                    <FontAwesomeIcon icon={faTrashAlt} className="fa-lg" />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Row>
      <VerticallyCenteredModal
        show={modalShow}
        onHide={modalHide}
        title={
          action === "new"
            ? "Add Enrolled Course"
            : action === "update"
            ? "Update Enrolled Course"
            : "Remove Enrolled Course"
        }
        body={
          action === "new" ? (
            <EnrolledCourse student_id={id} action={action} hide={modalHide} />
          ) : action === "update" ? (
            "Update"
          ) : (
            <Remove cancel={modalHide} remove={remove} />
          )
        }
      />
    </Container>
  );
};

const mapStateToProps = ({ students, enrolledCourses }, otherProps) => {
  const { id } = otherProps.router.params;

  const student = Object.values(students)
    .filter((student) => student.id === parseInt(id))
    .pop();

  enrolledCourses = Object.values(enrolledCourses);

  return {
    id,
    student,
    enrolledCourses,
  };
};

export default withRouter(connect(mapStateToProps)(StudentInfo));
