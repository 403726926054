import { serverUrl } from "./app";
import axios from "axios";
import qs from "qs";

export function _fetchSchedules() {
  return axios.get(serverUrl(), {
    params: {
      tag: "schedules",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _fetchSchedule(id) {
  return axios.get(serverUrl(), {
    params: {
      tag: "schedule",
      id,
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _createSchedule(schedule) {
  delete schedule.id;
  const data = {
    path: "create_schedule",
    schedule,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _updateSchedule(schedule) {
  const data = {
    path: "update_schedule",
    schedule,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _removeSchedule(id) {
  const data = {
    path: "remove_schedule",
    id,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}
