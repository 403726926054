import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageHeader from "../PageHeader";
import { connect } from "react-redux";
import { handleNewUser, handleUpdateUser } from "../../actions/users";
import { useNavigate, useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const withRouter = (Component) => {
  const ComponentWithProp = (props) => {
    const params = useParams();
    return <Component {...props} router={{ params }} />;
  };

  return ComponentWithProp;
};

const User = ({ dispatch, user }) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required().email(),
    phone: yup.string().required().min(13).max(13),
    role: yup.string().required(),
  });

  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Users",
      path: "/users",
    },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log(data);
    if (id !== "new") {
      data.id = parseInt(id);
      dispatch(handleUpdateUser(data));
    } else {
      data.password = Math.random().toString(36).slice(2);
      dispatch(handleNewUser(data));
    }
    navigate("/users");
  };

  useEffect(() => {
    if (user && id !== "new") {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("phone", user.phone);
      setValue("role", user.role);
    }
  }, [user, id, setValue]);

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"User"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs lg={6}>
          <form
            id="course-form"
            className="row"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="col-md-12 mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                name={"name"}
                className={"form-control"}
                {...register("name")}
              />
              <span className="text-danger">{errors.name?.message}</span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Email</label>
              <input
                type="text"
                name="email"
                className={"form-control"}
                {...register("email")}
              />
              <span className="text-danger">{errors.email?.message}</span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Phone</label>
              <input
                name="phone"
                className={"form-control"}
                {...register("phone")}
              />
              <span className="text-danger">{errors.phone?.message}</span>
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Role</label>
              <select
                name="role"
                className={"form-select"}
                {...register("role")}
              >
                <option value="">Select role</option>
                <option value="admin">Admin</option>
                <option value="asst_admin">Asst. Admin</option>
                <option value="staff">Staff</option>
              </select>
              <span className="text-danger">{errors.factor?.message}</span>
            </div>
            <div className="col-12 mt-3 d-flex justify-content-end">
              <button
                id="btnAdd"
                type="submit"
                className="btn btn-block btn-success"
              >
                Submit
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ users }, otherProps) => {
  const { id } = otherProps.router.params;

  let user;

  if (id) {
    user = Object.values(users)
      .filter((user) => user.id === parseInt(id))
      .pop();
  } else {
    user = {
      name: "",
      email: "",
      phone: "",
      role: "",
    };
  }

  return {
    user,
  };
};

export default withRouter(connect(mapStateToProps)(User));
