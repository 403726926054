import { useState } from "react";
import { _login } from "../utils/_DATA";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { setAuthedUser } from "../actions/authedUser";
import { connect } from "react-redux";

const Login = (props) => {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const login = (event) => {
    event.preventDefault();

    const data = {
      path: "login",
      email,
      password,
    };

    if (!email || !password) {
      setError(true);
      setMessage("Enter email and password");
    } else {
      _login(data)
        .then((response) => {
          if (response.status === 200) {
            const user = response.data.user;
            user.authorization = response.data.authorization.rules;

            sessionStorage.setItem("loggedIn", true);
            sessionStorage.setItem("token", response.data.token);
            sessionStorage.setItem("token_exp", response.data.token_exp);
            sessionStorage.setItem("user", JSON.stringify(response.data.user));
            sessionStorage.setItem(
              "authorize",
              response.data.authorization.rules
            );

            setError(false);
            setMessage("");
            props.dispatch(setAuthedUser(user));

            // appAlert("success", response.data.message);
          }
        })
        .catch((error) => {
          // this.loading = false;
          // appAlert("danger", error.response.data.message);
          setError(true);
          setMessage(error.response.data.message);
        });
    }
  };

  return (
    <Container className="vh-100">
      <Row className="vh-100 justify-content-md-center align-items-center">
        <Col xs lg="4">
          {error && (
            <div
              data-testid="error-header"
              className="w3-panel w3-red w3-padding"
            >
              <p>{message}</p>
            </div>
          )}

          <h2>Login</h2>
          <hr></hr>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                data-testid="email-input"
                value={email}
                onChange={handleEmailChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                data-testid="password-input"
                value={password}
                onChange={handlePasswordChange}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="primary" type="submit" onClick={login}>
                Submit
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (props) => props;

export default connect(mapStateToProps)(Login);
