import { combineReducers } from "redux";
import authedUser from "./authedUser";
import student from "./student";
import students from "./students";
import courses from "./courses";
import users from "./users";
import toast from "./toast";
import enrolledCourses from "./enrolledCourses";
import coursePayments from "./coursePayments";
import paymentReport from "./paymentReport";
import paymentDetails from "./paymentDetails";
import schedules from "./schedules";

export default combineReducers({
  authedUser,
  student,
  students,
  courses,
  users,
  enrolledCourses,
  coursePayments,
  paymentReport,
  paymentDetails,
  schedules,
  toast,
});
