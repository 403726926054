import { serverUrl } from "./app";
import axios from "axios";
import qs from "qs";

export function _fetchCoursePayments() {
  return axios.get(serverUrl(), {
    params: {
      tag: "course_payments",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _fetchStudentCoursePayments(student_id, enrolled_course_id) {
  return axios.get(serverUrl(), {
    params: {
      tag: "student_course_payments",
      student_id: student_id,
      enrolled_course_id: enrolled_course_id,
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _pay(course_payment) {
  const data = {
    path: "pay_course_payment",
    course_payment: course_payment,
  };

  return axios.post(serverUrl(), qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _fetchPaymentReport() {
  return axios.get(serverUrl(), {
    params: {
      tag: "payment_report",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _fetchFullyPaidStudents() {
  return axios.get(serverUrl(), {
    params: {
      tag: "fully_paid_students",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}

export function _fetchOwingStudents() {
  return axios.get(serverUrl(), {
    params: {
      tag: "owing_students",
    },
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    },
  });
}
