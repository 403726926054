export const SHOW_TOAST = "SHOW_TOAST";

export function showToast(data) {
  return {
    type: SHOW_TOAST,
    data,
  };
}

export function handleShowToast(data) {
  return showToast(data);
}
