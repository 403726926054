import { _fetchCoursePayments, _pay } from "../utils/course_payments";

export const FETCH_COURSE_PAYMENTS = "FETCH_COURSE_PAYMENTS";

export function fetchCoursePayments(course_payments) {
  return {
    type: FETCH_COURSE_PAYMENTS,
    course_payments,
  };
}

export function handleFetchCoursePayments() {
  return (dispatch, getState) => {
    const { student } = getState();
    return _fetchCoursePayments(student.id).then((response) => {
      console.log(response);
      dispatch(fetchCoursePayments(response.data.course_payments));
    });
  };
}

export function handlePayment(coursePayment) {
  return (dispatch) => {
    return _pay(coursePayment).then((response) => {
      console.log(response);
      dispatch(handleFetchCoursePayments());
    });
  };
}
