import React from "react";
import ReactDOM from "react-dom/client";
import "../src/assets/css/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "../src/components/App";
import { createStore } from "redux";
import reducer from "./reducers";
import middleware from "./middleware";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

const store = createStore(reducer, middleware);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);
