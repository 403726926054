import {
  _createSchedule,
  _updateSchedule,
  _removeSchedule,
} from "../utils/schedules";
import { handleShowToast } from "./toast";

export const FETCH_SCHEDULES = "FETCH_SCHEDULES";
export const FETCH_SCHEDULE = "FETCH_SCHEDULE";
export const NEW_SCHEDULE = "NEW_SCHEDULE";
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const REMOVE_SCHEDULE = "REMOVE_SCHEDULE";

export function fetchSchedules(users) {
  return {
    type: FETCH_SCHEDULES,
    users,
  };
}

export function fetchSchedule(schedule) {
  return {
    type: FETCH_SCHEDULE,
    schedule,
  };
}

export function newSchedule(schedule) {
  return {
    type: NEW_SCHEDULE,
    schedule,
  };
}

export function handleNewSchedule(schedule) {
  return (dispatch) => {
    return _createSchedule(schedule).then((response) => {
      if (response.status === 200) {
        schedule.id = response.data.id;
        dispatch(newSchedule(schedule));
        dispatch(
          handleShowToast({
            show: true,
            title: "Schedule",
            message: response.data.message,
            bg: "success",
          })
        );
      } else {
      }
    });
  };
}

export function updateSchedule(schedule) {
  return {
    type: UPDATE_SCHEDULE,
    schedule,
  };
}

export function handleUpdateSchedule(schedule) {
  return (dispatch) => {
    return _updateSchedule(schedule).then((response) => {
      dispatch(updateSchedule(schedule));
      dispatch(
        handleShowToast({
          show: true,
          title: "Schedule",
          message: response.data.message,
          bg: "success",
        })
      );
    });
  };
}

export function removeSchedule(id) {
  return {
    type: REMOVE_SCHEDULE,
    id,
  };
}

export function handleRemoveSchedule(schedule) {
  return (dispatch) => {
    return _removeSchedule(schedule.id).then((response) => {
      if (response.status === 200) {
        dispatch(removeSchedule(schedule.id));
        dispatch(
          handleShowToast({
            show: true,
            title: "Schedule",
            message: response.data.message,
            bg: "success",
          })
        );
      }
    });
  };
}
