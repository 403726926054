import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PageHeader from "../PageHeader";

const OwingPaidStudents = ({ dispatch, students }) => {
  const crumbs = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Students",
      path: "/students",
    },
    {
      name: "Courses",
      path: "/courses",
    },
  ];

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <PageHeader pageTitle={"Owing Paid Students"} crumbs={crumbs} />
        </Col>
      </Row>
      <Row>
        <Col>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Course</th>
                <th scope="col">Cost</th>
                <th scope="col">Total Discount</th>
                <th scope="col">Total Paid</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {students.map((student, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{student.student_name}</td>
                  <td>{student.course_name}</td>
                  <td>{student.amount_due}</td>
                  <td>{student.total_discount}</td>
                  <td>{student.total_paid}</td>
                  <td>
                    <Link
                      to={`/payment_details/${student.student_id}/${student.enrolled_course_id}`}
                    >
                      Details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ paymentReport }) => {
  const students = Object.values(paymentReport).filter(
    (item) => item.total_discount + item.total_paid !== item.amount_due
  );

  return {
    students,
  };
};

export default connect(mapStateToProps)(OwingPaidStudents);
