import {
  _fetchStudent,
  _createStudent,
  _updateStudent,
  _removeStudent,
} from "../utils/students";
import { handleShowToast } from "./toast";

export const FETCH_STUDENTS = "FETCH_STUDENTS";
export const FETCH_STUDENT = "FETCH_STUDENT";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const NEW_STUDENT = "NEW_STUDENT";
export const REMOVE_STUDENT = "REMOVE_STUDENT";

export function fetchStudents(students) {
  return {
    type: FETCH_STUDENTS,
    students,
  };
}

export function fetchStudent(student) {
  return {
    type: FETCH_STUDENT,
    student,
  };
}

export function newStudent(student) {
  return {
    type: NEW_STUDENT,
    student,
  };
}

export function handleFetchStudent(id) {
  return (dispatch) => {
    return _fetchStudent(id).then((response) => {
      dispatch(fetchStudent(response.data.student));
    });
  };
}

export function handleNewStudent(student) {
  return (dispatch) => {
    return _createStudent(student).then((response) => {
      if (response.status === 200) {
        student.id = response.data.id;
        dispatch(newStudent(student));
        dispatch(
          handleShowToast({
            show: true,
            title: "Student",
            message: response.data.message,
            bg: "success",
          })
        );
      } else {
      }
    });
  };
}

export function updateStudent(student) {
  return {
    type: UPDATE_STUDENT,
    student,
  };
}

export function handleUpdateStudent(student) {
  return (dispatch) => {
    return _updateStudent(student).then((response) => {
      dispatch(updateStudent(student));
      dispatch(
        handleShowToast({
          show: true,
          title: "Student",
          message: response.data.message,
          bg: "success",
        })
      );
    });
  };
}

export function removeStudent(id) {
  return {
    type: REMOVE_STUDENT,
    id,
  };
}

export function handleRemoveStudent(student) {
  return (dispatch) => {
    return _removeStudent(student.id).then((response) => {
      if (response.status === 200) {
        dispatch(removeStudent(student.id));
        dispatch(
          handleShowToast({
            show: true,
            title: "Student",
            message: response.data.message,
            bg: "success",
          })
        );
      }
    });
  };
}
