import {
  FETCH_STUDENTS,
  FETCH_STUDENT,
  UPDATE_STUDENT,
  NEW_STUDENT,
  REMOVE_STUDENT,
} from "../actions/students";

export default function students(state = {}, action) {
  switch (action.type) {
    case FETCH_STUDENTS:
      return {
        ...state,
        ...action.students,
      };
    case FETCH_STUDENT:
      return {
        ...state,
        ...action.student,
      };
    case NEW_STUDENT:
      return {
        ...state,
        [Object.keys(state).length]: action.student,
      };
    case UPDATE_STUDENT:
      state = Object.values(state).filter(
        (student) => student.id !== parseInt(action.student.id)
      );
      state.push(action.student);
      return {
        ...state,
      };
    case REMOVE_STUDENT:
      state = Object.values(state).filter(
        (student) => student.id !== action.id
      );
      return {
        ...state,
      };
    default:
      return state;
  }
}
