import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DashboardMenuBarItem = (props) => {
  return (
    <Link to={props.path} style={{ color: "#fff" }}>
      <FontAwesomeIcon icon={props.icon} size="2x" color="#fff" />
      <div className="pb-3">{props.name}</div>
    </Link>
  );
};

export default DashboardMenuBarItem;
