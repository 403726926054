import {
  FETCH_SCHEDULES,
  FETCH_SCHEDULE,
  UPDATE_SCHEDULE,
  NEW_SCHEDULE,
  REMOVE_SCHEDULE,
} from "../actions/schedules";

export default function schedules(state = {}, action) {
  switch (action.type) {
    case FETCH_SCHEDULES:
      return {
        ...state,
        ...action.users,
      };
    case FETCH_SCHEDULE:
      return {
        ...state,
        ...action.schedule,
      };
    case NEW_SCHEDULE:
      return {
        ...state,
        [Object.keys(state).length]: action.schedule,
      };
    case UPDATE_SCHEDULE:
      state = Object.values(state).filter(
        (schedule) => schedule.id !== parseInt(action.schedule.id)
      );
      state.push(action.schedule);
      return {
        ...state,
      };
    case REMOVE_SCHEDULE:
      state = Object.values(state).filter(
        (schedule) => schedule.id !== action.id
      );
      return {
        ...state,
      };
    default:
      return state;
  }
}
