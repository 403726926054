import {
  _fetchEnrolledCourses,
  _createEnrolledCourse,
  _removeEnrolledCourse,
} from "../utils/enrolled_courses";

import { handleShowToast } from "./toast";

export const FETCH_ENROLLED_COURSES = "FETCH_ENROLLED_COURSES";
export const ADD_ENROLLED_COURSE = "ADD_ENROLLED_COURSE";

export function fetchEnrolledCourses(enrolled_courses) {
  return {
    type: FETCH_ENROLLED_COURSES,
    enrolled_courses,
  };
}

export function handleFetchEnrolledCourses() {
  return (dispatch, getState) => {
    const { student } = getState();
    return _fetchEnrolledCourses(student.id).then((response) => {
      dispatch(fetchEnrolledCourses(response.data.enrolled_courses));
    });
  };
}

export function AddEnrolledCourse(enrolled_course) {
  return {
    type: ADD_ENROLLED_COURSE,
    enrolled_course,
  };
}

export function handleAddEnrolledCourse(enrolled_course, course_payment) {
  return (dispatch) => {
    return _createEnrolledCourse(enrolled_course, course_payment).then(
      (response) => {
        if (response.status === 200) {
          dispatch(handleFetchEnrolledCourses());
          dispatch(
            handleShowToast({
              show: true,
              title: "Enrolled Course",
              message: response.data.message,
              bg: "success",
            })
          );
        } else {
        }
      }
    );
  };
}

export function handleRemoveEnrolledCourse(id) {
  return (dispatch) => {
    return _removeEnrolledCourse(id).then((response) => {
      if (response.status === 200) {
        dispatch(handleFetchEnrolledCourses());
        dispatch(
          handleShowToast({
            show: true,
            title: "Enrolled Course",
            message: response.data.message,
            bg: "success",
          })
        );
      } else {
      }
    });
  };
}
