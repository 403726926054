import {
  _fetchCourses,
  _createCourse,
  _updateCourse,
  _removeCourse,
} from "../utils/courses";
import { handleShowToast } from "./toast";

export const FETCH_COURSES = "FETCH_COURSES";
export const FETCH_COURSE = "FETCH_COURSE";
export const NEW_COURSE = "NEW_COURSE";
export const UPDATE_COURSE = "UPDATE_COURSE";
export const REMOVE_COURSE = "REMOVE_COURSE";

export function fetchCourses(courses) {
  return {
    type: FETCH_COURSES,
    courses,
  };
}

export function fetchCourse(course) {
  return {
    type: FETCH_COURSE,
    course,
  };
}

export function newCourse(course) {
  return {
    type: NEW_COURSE,
    course,
  };
}

export function handleFetchCourses() {
  return (dispatch) => {
    return _fetchCourses().then((response) => {
      console.log(response);
      dispatch(fetchCourses(response.data.enrolled_courses));
    });
  };
}

export function handleNewCourse(course) {
  return (dispatch) => {
    return _createCourse(course).then((response) => {
      if (response.status === 200) {
        course.id = response.data.id;
        dispatch(newCourse(course));
        dispatch(
          handleShowToast({
            show: true,
            title: "Course",
            message: response.data.message,
            bg: "success",
          })
        );
      } else {
      }
    });
  };
}

export function updateCourse(course) {
  return {
    type: UPDATE_COURSE,
    course,
  };
}

export function handleUpdateCourse(course) {
  return (dispatch) => {
    return _updateCourse(course).then((response) => {
      dispatch(updateCourse(course));
      dispatch(
        handleShowToast({
          show: true,
          title: "Course",
          message: response.data.message,
          bg: "success",
        })
      );
    });
  };
}

export function removeCourse(id) {
  return {
    type: REMOVE_COURSE,
    id,
  };
}

export function handleRemoveCourse(course) {
  return (dispatch) => {
    return _removeCourse(course.id).then((response) => {
      if (response.status === 200) {
        dispatch(removeCourse(course.id));
        dispatch(
          handleShowToast({
            show: true,
            title: "Course",
            message: response.data.message,
            bg: "success",
          })
        );
      }
    });
  };
}
